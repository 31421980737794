<template>
  <div class="je-table-apply">
    <el-row class="margin-top-40 ">
      <el-col :span="8">
        <!-- 快速查询的菜单 -->
        <div class="padding-left-20">
          <el-dropdown @command="pitchOnSelect">
            <span
              class="color-F84E4E font-size-24 font-weight-500 cursor-pointer-hover display-block border-bottom-2-solid-F84E4E padding-bottom-5"
            >
              {{ thisMenuSelect.name
              }}<i
                class="jeicon jeicon-sort-small-down font-size-20 margin-left-10"
              />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(selectObj, _select) in menuSelect"
                :key="_select"
                :command="selectObj"
              >
                <span>
                  {{ selectObj.name }}
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
      <el-col
        class="elemnt-input-apply"
        :span="16"
        style="text-align: right;"
      >
        <span
          class="font-size-16 color-3F3F3F vertical-align-middle"
        >关键词：</span>
        <el-input
          v-model="SearchData.gjc"
          clearable
          class="border-none vertical-align-middle"
          placeholder="商品名称/订单号"
          @keyup.enter.native="doSearch"
          @clear="refreshInit()"
        >
          <i
            slot="prefix"
            :class="(SearchData.gjc && 'cursor-pointer-hover-F9514F') || ''"
            class="el-input__icon el-icon-search"
            @click="doSearch"
          />
        </el-input>
      </el-col>
      <!-- <el-col
        class="elemnt-input-apply"
        :span="8"
      >
        <span class="font-size-16 color-3F3F3F vertical-align-middle">证书号：</span>
        <el-select
          v-model="SearchData.zsh"
          clearable
          popper-class="ZSH-select-number"
          style="width: 80%"
          placeholder="请选择证书号"
          @change="initDatas"
        >
          <el-option
            v-for="(item, _index) in orderNumbers"
            :key="_index"
            :label="item.values.DDGL_GLZS"
            :value="item.values.DDGL_GLZS"
          />
        </el-select>
      </el-col> -->
    </el-row>
    <!-- 表单头 -->
    <div
      class="margin-top-35 background-FFFFFF padding-TB-10 padding-LR-20 border-radius-5"
    >
      <el-row class="font-weight-500">
        <el-col :span="6">
          <div class="font-size-20 color-3F3F3F orderDate">
            订单日期
          </div>
        </el-col>
        <el-col :span="5">
          <div class="font-size-20 color-3F3F3F orderNum">
            订单编号
          </div>
        </el-col>
        <el-col :span="6">
          <div class="font-size-20 color-3F3F3F text-status-center">
            商品名称
          </div>
        </el-col>
        <el-col :span="7">
          <div class="font-size-20 color-3F3F3F text-status-center">
            金额
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 全选 -->
    <div
      v-if="applyListData.length > 0"
      class="margin-top-10 padding-left-15"
    >
      <el-checkbox
        :indeterminate="indeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      >
        全选
      </el-checkbox>
    </div>
    <!-- 主要内容 -->
    <div class="margin-top-10 ">
      <div
        class="background-FFFFFF padding-LR-15 line-height-54 height-54  border-radius-5 position-relative"
      >
        <Loading :loadingData="{isShow: loading}" />
        <div
          v-if="applyListData.length > 0"
          class="min-height-500"
        >
          <el-row
            v-for="(alreadyList, _index) in applyListData"
            :key="_index"
          >
            <el-row
              v-if="alreadyList.cjjg > 0"
              class="border-bottom-2-solid-F2F2F2 height-align padding-bottom-10 padding-top-10"
            >
              <el-col
                :span="1"
                class=""
              >
                <el-checkbox
                  v-model="alreadyList.check"
                  class="vertical-align-middle"
                  @change="handleCheckedCitiesChange(alreadyList)"
                />
              </el-col>
              <el-col :span="5">
                <div class="font-size-18 color-3F3F3F ">
                  {{ alreadyList.orderDate }}
                </div>
              </el-col>
              <el-col :span="5">
                <div class="font-size-18 color-3F3F3F">
                  {{ alreadyList.orderBh }}
                </div>
              </el-col>
              <el-col :span="6">
                <div class="font-size-18 color-3F3F3F text-status-center">
                  {{ alreadyList.productName }}
                </div>
              </el-col>
              <el-col :span="7">
                <div class="font-size-18 color-F9514F text-status-center">
                  ¥{{ alreadyList.cjjg.toFixed(2) }}
                </div>
              </el-col>
            </el-row>
          </el-row>
        </div>
        <!-- 无订单 -->
        <div
          v-else
          style="text-align: center;margin:0 auto;padding: 50px 0"
        >
          <img
            src="../../../../detail/assets/images/alreadyIndent/nullData.png"
            alt
          >
          <div class="color-BEBEBE font-size-14">
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <div class="background-FFFFFF text-status-center padding-top-20">
      <el-pagination
        background
        :page-size="10"
        :current-page="SearchData.currPage"
        layout="prev, pager, next, jumper"
        :total="totalCount"
        @current-change="pageHandleChange"
      />
    </div>
    <div class="background-FFFFFF text-status-center padding-bottom-20">
      <div class="line_con padding-bottom-30 padding-top-30 font-size-22 color-3F3F3F text-status-right">
        总金额：
        <span class="color-F9514F"> {{ moneyAll.toFixed(2) }}元 </span>
      </div>
      <div
        class="nextstep text-status-center width-500 margin-top-40 height-50 line-height-50 background-F6474E color-FFFFFF font-size-18 border-radius-10 display-inlineBlock cursor-pointer-hover"
        @click="nextStep"
      >
        下一步
      </div>
      <div
        v-if="applyListProp.type == 'FP'"
        class="ticket_box"
      >
        <img
          src="@/assets/imgs/buying/invoice.png"
          class="ticket_con"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import { isArray } from '@/util/util';
import { POST_ORDER_TYPE, POST_ORDER_LIST, POST_ORDER_SYZS } from '@/pages/index/actions/api';

export default {
  props: {
    applyListProp: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      indeterminate: false,
      allDatas: [], // 所有的数据不会因为是否选中而变少
      orderNumbers: [], // 证书订单号下拉
      applyListData: [], // 订单数据
      totalCount: 0,
      loading: true,
      checkAll: false, // 全选
      checkData: [], // 选中数据
      selections: [], // 收集选中的订单ID
      moneyAll: 0, // ¥
      SearchData: {
        type: '',
        gjc: '',
        zsh: '',
        currPage: 1,
      },
      thisMenuSelect: {
        name: '全部',
        code: '',
      },
      menuSelect: [], // 快速筛选的下拉菜单选择
    };
  },
  created() {
    this.initDatas();// 列表数据
    this.initCode();// 分类筛选
    this.getUserCertificate(); // 获取用户的商业证书号
    this.moneyAll = 0;
  },
  methods: {
    // 初始化列表数据
    refreshInit() {
      this.SearchData.currPage = 1;
      this.initDatas();// 列表数据
    },
    initDatas() {
      this.loading = true;
      this.$ajax({
        url: POST_ORDER_LIST,
        data: {
          printType: this.applyListProp.type,
          type: this.SearchData.type || '',
          zsh: this.SearchData.zsh || '',
          gjc: this.SearchData.gjc || '',
          pageSize: 10, // 当前每页显示的条数
          currPage: this.SearchData.currPage, // 当前的页数
        },
      }).then((order) => {
        const rows = order.data && order.data.rows;
        this.loading = false;
        if (rows) {
          this.totalCount = order.data.totalCount;
          rows.forEach((item) => {
            const index = this.selections.indexOf(item.orderId);
            if (index > -1) {
              item.check = true;
            } else {
              item.check = false;
            }
          });
          this.applyListData = rows;
        }
      });
    },
    //
    initCode() {
      this.$ajax({
        url: POST_ORDER_TYPE,
        data: {
          dictionaryCode: 'JEPAAS_SPSSFL',
        },
      }).then((item) => {
        const itemData = item.data.obj.map(obj => ({ name: obj.DICTIONARYITEM_ITEMNAME, code: obj.DICTIONARYITEM_ITEMCODE }));
        this.menuSelect = [{
          name: '全部',
          code: '',
        }, ...itemData];
      });
    },
    // 获取用户下的商业证书号
    getUserCertificate() {
      this.$ajax({
        url: POST_ORDER_SYZS,
      }).then((order) => {
        const data = order.data.obj;
        if (order.data.success && isArray(data)) {
          this.orderNumbers = data.filter(item => item.values.DDGL_GLZS);
        }
      });
    },
    // 快速查询之商业证书查询
    orderNumbersChange(val) {
      this.SearchData.zsh = val;
      this.getOrderList(true);
    },
    // 点击分页
    pageHandleChange(val) {
      this.SearchData.currPage = val;
      this.initDatas();
    },
    // 选择下拉菜单类型
    pitchOnSelect(obj) {
      this.SearchData.type = obj.code;
      this.thisMenuSelect.name = obj.name;
      this.moneyAll = 0;
      this.checkAll = false;
      this.handleCheckAllChange(false);
      if (!obj.code) {
        this.SearchData = {
          type: '',
          currPage: 1,
          gjc: '',
          zsh: '',
        };
        this.initDatas();
        return;
      }
      this.SearchData.currPage = 1;
      // 清掉选中状态
      this.selections = [];
      this.initDatas();
    },
    // 输入框查询
    doSearch() {
      // 清掉选中状态
      this.checkAll = false;
      this.indeterminate = false;
      this.selections = [];
      this.moneyAll = 0;
      this.initDatas();
    },
    // 全选
    handleCheckAllChange(value) {
      this.indeterminate = false; // 点击全选之后将全选的半选状态取消掉
      if (value) {
        this.getAlldatas((list) => {
          this.moneyAll = 0;
          list.forEach((item) => {
            if (item.check) {
              this.selections.push(item.orderId);
              this.moneyAll += item.cjjg;
            }
          });
          this.checkData = list.filter(item => item.check);
          this.applyListData.forEach((item) => {
            item.check = true;
          });
        });
      } else {
        this.selections = [];
        this.checkData = [];
        this.moneyAll = 0;
        this.applyListData.forEach((item) => {
          item.check = false;
        });
      }
    },
    // 获取所有数据
    getAlldatas(cb) {
      this.$ajax({
        url: POST_ORDER_LIST,
        data: {
          printType: this.applyListProp.type,
          type: this.SearchData.type || '',
          zsh: this.SearchData.zsh || '',
          gjc: this.SearchData.gjc || '',
          currPage: -1, // 获取全部
        },
      }).then((order) => {
        const rows = order.data && order.data.rows;
        if (rows) {
          rows.forEach((item) => {
            // 如果已经勾选了全选
            if (this.checkAll) {
              item.check = true;
            } else {
              item.check = false;
            }
          });
          this.allDatas = rows;
          cb && cb(rows);
        }
      });
    },
    // 单选
    handleCheckedCitiesChange(em) {
      if (em.check) {
        this.selections.push(em.orderId);
        this.checkData.push(em);
      } else {
        const index = this.selections.indexOf(em.orderId);
        if (index > -1) {
          this.selections.splice(index, 1);
          for (let i = this.checkData.length - 1; i >= 0; i--) {
            if (this.checkData[i].orderId == em.orderId) {
              this.checkData.splice(i, 1); // 执行后 length会减一
            }
          }
        }
      }
      this.checkAll = this.checkData.length == this.allDatas.length;
      this.checkAll && (this.indeterminate = false) || (this.indeterminate = true);
      this.setMoneyAll();
    },
    setMoneyAll() {
      this.moneyAll = 0;
      this.checkData.forEach((item) => {
        if (item.check) {
          this.moneyAll += item.cjjg;
        }
      });
    },
    nextStep() {
      if (this.selections.length == 0) {
        this.$message.error('请至少选择一个订单！');
      } else {
        const initFormData = this.checkData.map(item => item.orderId);
        const obj = { moneyAll: this.moneyAll, initFormData: initFormData.toString() };
        this.$emit('subForm', this.applyListProp.type, obj);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../style/shop/util';
</style>
<style lang="less">
.je-table-apply {
  .is-indeterminate {
    .el-checkbox__inner{
      background: #f6474e;
    border-color: #f6474e;
    }
    .el-checkbox__inner::before {
      left: 9px;
      top: 17px;
      margin-top: -50%;
      margin-left: -50%;
    }
  }
  .el-input {
    width: 350px;
  }
  .orderDate {
    padding-left: 80px;
  }
  .orderNum {
    padding-left: 35px;
  }
  .el-pagination {
    text-align: right;
    padding-right: 20px;
    .el-pagination__editor {
      width: 50px;
    }
  }
  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .el-checkbox__inner::after {
    left: 16px;
    top: 13px;
    margin-top: -50%;
    margin-left: -50%;
  }
  .is-checked {
    .el-checkbox__inner {
      background: #f6474e;
      border-color: #f6474e;
    }
    .el-checkbox__label {
      color: #f6474e;
    }
  }
  .elemnt-input-apply .el-input__inner {
    border: none;
    border-radius: 20px;
  }
  .el-pagination.is-background .el-pager .active {
    background-color: #f6474e !important;
  }
  .height-align {
    display: flex;
    align-items: center;
  }
  .line_con {
    box-sizing: border-box;
    width: 100%;
    padding-right: 20px;
    border-bottom: 1px dashed rgba(169, 171, 169, 1);
  }
  .nextstep:hover {
    opacity: .8;
  }
  .ticket_box {
    margin: 50px auto;
    width: 923px;
    height: 625px;
    background: rgba(245, 245, 245, 1);
    border-radius: 8px;
    border: 1px solid rgba(222, 222, 222, 1);
    .ticket_con {
      margin-top: 20px;
      width: 883px;
      height: 585px;
    }
  }
}
</style>
